<template>
  <v-row>
    <v-col cols="12" md="4" >
      <v-radio-group v-model="value.tipo" row 
       dense
        :rules="[
                this.$validators.string.required,
          ]">
        <v-radio
          label="Lista Matrícula"
          value="LM"
          :checked=true
        ></v-radio>
        <v-radio
          label="Histórico Matrícula"
          value="HM"
        ></v-radio>
      </v-radio-group>
    </v-col>
    <v-col cols="12" md="4">
       <v-text-field label="Mês/Ano" v-show="value.tipo === 'HM'"
                    dense
                    type="month"
                    v-model="value.mesAno"
                    :value="this.mesAnoAtual"
                    :rules="[
                        this.$validators.string.required,
                  ]"/>
        <v-checkbox v-show="value.tipo === 'LM'"
          dense
          label="Exibir Histórico"
          v-model="value.exibirHistorico"
          value="S"></v-checkbox>
    </v-col>
    <v-col cols="12" md="4">
      <lookup-dominio label="Status"
                      v-model="value.status"
                      :type="lookups.status"
                      :hidden-clear="false"/>
    </v-col>    
    <v-col cols="12" md="6">
      <v-text-field label="Nome Aluno"
                    dense
                    v-model="value.nome"
                    :rules="[
                        v => this.$validators.string.greaterThanOrEquals(v, 1),
                        v => this.$validators.string.lessThanOrEquals(v, 100),
                    ]"/>
    </v-col>
    <v-col cols="12" md="6">
      <search-box
          :hidden-clear="false"
          label="Local"
          popup-label="Selecione um local"
          :id.sync="value.idLocal"
          :descricao.sync="value.descricao_idLocal"
          :value="local"
          :loader-fn="loaderLocal"
          item-key="id"
          item-text="descricao">
        <template v-slot:empty>
          <p class="ma-2">
            Nenhum local encontrado
          </p>
        </template>
      </search-box>
    </v-col>
  </v-row>
</template>

<script>

import {ItemDeDominio} from "../../../../api/itemsDominio";
import {findAll as findAllLocal} from "../../../../api/locais";
import LookupDominio from "../../common/LookupDominio";
import OrderInput from "../../common/OrderInput";
import SearchBox from "../../common/SearchBox";
import moment from 'moment';

export default {
  name: "RelatorioHistoricoMatriculaForm",
  components: {
    SearchBox,
    LookupDominio,
    OrderInput
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      lookups: {
        status: ItemDeDominio.STATUS_PADRAO,
      },
      loaderLocal(page, search) {
        return findAllLocal(page, {
          descricao: search
        })
      },
    }
  },
  computed: {
    local() {
      if (this.value.idLocal == null) return null;
      return {
        id: this.value.idLocal,
        descricao: this.value.descricao_idLocal
      }
    },
    mesAnoAtual: function () {
      if (typeof this.value.mesAno === "undefined"){
        this.value.mesAno = moment().format('YYYY-MM');
      }
    },
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}


</style>